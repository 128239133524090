import { hexToBytes } from '@chainflip/utils/bytes';
import { type ChainflipChain, chainflipChains } from '@chainflip/utils/chainflip';
import { type HexString } from '@chainflip/utils/types';
import { etherscanUrl } from './helpers';

type CamelCase<T extends string> = T extends `${infer F}${infer R}`
  ? `${Capitalize<F>}${Lowercase<R>}`
  : T;

export const toNumber = (string: string): number | typeof NaN => parseInt(string, 10);

export const ethTxLink = (hash: string): string => `${etherscanUrl()}/tx/${hash}`;

export const isIdSs58 = (value: string): boolean => value.startsWith('cF') && value.length === 49;

export const isValidValidatorHex = (text: string): boolean => {
  if (text) {
    return text.slice(0, 2) === '0x' && text.length === 66;
  }
  return false;
};

export const formatIpfsUrl = (stringURI: string) =>
  stringURI.startsWith('ipfs') ? `https://ipfs.io/ipfs/${stringURI.slice(7)}` : stringURI;

export const toCamelCase = <T extends string>(str: T): CamelCase<T> =>
  `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}` as CamelCase<T>;

export const hexToUtf8 = (hex: HexString): string => {
  const bytes = hexToBytes(hex);
  return new TextDecoder().decode(bytes);
};

const isChainflipChain = (value: string): value is ChainflipChain =>
  [...chainflipChains].includes(value as ChainflipChain);

export const parseChannelId = (id: string | string[] | undefined) => {
  if (typeof id !== 'string') return null;

  const matches = /^(\d+)-(\w+)-(\d+)$/.exec(id.toUpperCase());

  if (!matches) return null;

  const [, issuedBlockIdString, _sourceChain, channelId] = matches;
  const sourceChain = toCamelCase(_sourceChain);

  const issuedBlockId = Number.parseInt(issuedBlockIdString, 10);

  if (Number.isNaN(issuedBlockId)) return null;

  if (!isChainflipChain(sourceChain)) return null;

  return { issuedBlockId, sourceChain, channelId };
};

export const matchCaseInsensitive = (text: string, pattern: string): boolean =>
  // need to escape special characters
  new RegExp(pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i').test(text);

export const removeCommas = (value: string): string => value.replace(/,/g, '');

export const formatRuntimeVersion = (value: number | undefined): string => {
  if (typeof value === 'undefined') return '';
  const string = value.toString();
  const partLength = Math.ceil(string.length / 3);
  const padded = string.padStart(partLength * 3, '0');
  const major = Number(padded.slice(0, partLength));
  const minor = Number(padded.slice(partLength, partLength * 2));
  const patch = Number(padded.slice(partLength * 2, partLength * 3));
  return `v${major}.${minor}.${patch}`;
};
