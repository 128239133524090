import { type ChainflipChain } from '@chainflip/utils/chainflip';
import { type Chain as WagmiChain } from 'wagmi/chains';
import { isTestnet } from '@/shared/featureFlags';
import mainnetChains from './mainnet';
import testnetChains from './testnet';

export type ChainId =
  | `evm-${number}`
  | `cosmos-${string}`
  | 'dot'
  | 'dot-testnet'
  | 'btc'
  | 'btc-testnet'
  | 'sol'
  | 'sol-devnet'
  | 'sol-backspin';

export interface ChainData {
  id: ChainId;
  name: string;
  addressPlaceholder: string;
  wagmiChain: WagmiChain | undefined;
}

// Avoid name collision with toolkit and wagmi
export interface ChainflipChainData extends ChainData {
  chainflipId: ChainflipChain;
}

const allChains = [...mainnetChains, ...testnetChains];
export const chains: (ChainData | ChainflipChainData)[] = isTestnet ? testnetChains : mainnetChains;

export type ConfigChainId = (typeof allChains)[number]['id'];
export type SupportedChainId = ConfigChainId | ChainId;

export const chainById = (id: ChainId): ChainData | ChainflipChainData | undefined =>
  chains.find((c) => c.id === id);

export const isEvmChain = (chainId?: ChainId): chainId is `evm-${number}` =>
  chainId !== undefined && /evm-\d+/.test(chainId);

export const parseEvmChainId = (chainId?: ChainId): number | undefined => {
  if (!isEvmChain(chainId)) return undefined;
  return Number(chainId.split('-')[1]);
};

export const isSolanaChain = (
  chainId?: ChainId,
): chainId is 'sol' | 'sol-devnet' | 'sol-backspin' =>
  chainId === 'sol' || chainId === 'sol-devnet' || chainId === 'sol-backspin';

export * from './mainnet';
export * from './testnet';
