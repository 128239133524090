import { gql } from '../generated/cache';
import { type LpAccountWithBalancesFragment } from '../generated/cache/graphql';

gql(/* GraphQL */ `
  fragment Pool on Pool {
    id
    baseAsset
    quoteAsset
    rangeOrderPrice
  }
`);

export type LpBalance = LpAccountWithBalancesFragment['balances']['nodes'][number];
export const getFilteredLpAccountsWithBalancesQuery = gql(/* GraphQL */ `
  query GetFilteredLpAccountsWithBalances($filter: String) {
    lps: allLpAccounts(
      filter: {
        or: [
          { idSs58: { includesInsensitive: $filter } }
          { alias: { includesInsensitive: $filter } }
        ]
      }
    ) {
      totalCount
      nodes {
        ...LpAccountWithBalances
      }
    }
    pools: allPools {
      nodes {
        ...Pool
      }
    }
  }
`);
