const Volume = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      d="M4.16666 5.84393H3.83332C3.46514 5.84393 3.16666 6.14241 3.16666 6.5106V10.1773C3.16666 10.5455 3.46514 10.8439 3.83332 10.8439H4.16666C4.53484 10.8439 4.83332 10.5455 4.83332 10.1773V6.5106C4.83332 6.14241 4.53484 5.84393 4.16666 5.84393Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.16666 3.17731H7.83332C7.46512 3.17731 7.16666 3.47579 7.16666 3.84397V10.1773C7.16666 10.5455 7.46512 10.844 7.83332 10.844H8.16666C8.53486 10.844 8.83332 10.5455 8.83332 10.1773V3.84397C8.83332 3.47579 8.53486 3.17731 8.16666 3.17731Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.16666 12.8439H12.8333"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1667 5.84393H11.8333C11.4651 5.84393 11.1667 6.14241 11.1667 6.5106V10.1773C11.1667 10.5455 11.4651 10.8439 11.8333 10.8439H12.1667C12.5349 10.8439 12.8333 10.5455 12.8333 10.1773V6.5106C12.8333 6.14241 12.5349 5.84393 12.1667 5.84393Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Volume;
