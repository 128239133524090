import { toEndOfUtcDay, toStartOfUtcDay } from '@chainflip/utils/date';
import { UTCDate } from '@date-fns/utc';
import { format, subDays } from 'date-fns';
import { FLIP_SYMBOL } from '@/shared/utils/env';
import TokenAmount from './TokenAmount';
import { FIRST_SWAP_DATE } from '../constants';

// format to number{s} or number{m} or number{h}
export const formatToApproxTime = (durationSeconds: number) => {
  if (durationSeconds < 60) {
    return `${durationSeconds.toFixed()}s`;
  }

  if (durationSeconds < 3600) {
    return `${Math.floor(durationSeconds / 60)}m`;
  }

  return `${Math.floor(durationSeconds / 3600)}h`;
};

export const pad = (number: number) => String(number).padStart(2, '0');

export const addToGoogleCalendar = (amount: bigint, startTimestamp: Date, endTimestamp: Date) => {
  const flipAmount = new TokenAmount(amount).toFixed();
  const callbackUrl = process.env.NEXT_PUBLIC_REDEMPTIONS_CALENDAR_CALLBACK_URL;

  const formatDateForCalendar = (date: Date) => format(date, "yyyyLLdd'T'HHmmss");
  const start = formatDateForCalendar(startTimestamp);
  const end = formatDateForCalendar(endTimestamp);

  const GoogleCalenderUrl = 'https://calendar.google.com/calendar/render';

  const queryParams = {
    action: 'TEMPLATE',
    dates: `${start}/${end}`,
    text: `Redeem ${flipAmount} ${FLIP_SYMBOL}`,
    details: `Finalise redemption of ${flipAmount} ${FLIP_SYMBOL} from <a href="${callbackUrl}">${callbackUrl}</a>`,
  };

  const url = `${GoogleCalenderUrl}?${new URLSearchParams(queryParams).toString()}`;

  window.open(url, '_blank');
};

// eg: "1h 2m", "expired", "less than a minute"
export const formatTimeUntilExpiry = (duration: number, expiredText = 'Expired') => {
  if (duration <= 0) {
    return expiredText;
  }

  if (duration <= 60) {
    return 'less than a minute';
  }

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);

  const hoursRepresentation = hours > 0 ? `${hours}h` : '';
  const minutesRepresentation = minutes > 0 ? `${minutes}m` : '';

  return `${hoursRepresentation} ${minutesRepresentation}`;
};

export const getDateTruncatedToHour = (date: Date) => {
  const year = String(date.getUTCFullYear());
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getMonth() returns a zero-based value for the month (zero is the first month)
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = '00';
  const seconds = '00';

  return new Date(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
};

export const getRemainingRetryTime = (fokDuration: number, scheduledAt: number): number =>
  fokDuration * 6 - (Date.now() - scheduledAt) / 1000;

// Date picker utils
export type DatesRange = [startDate: UTCDate, endDate: UTCDate];

export const minSwapDate = new UTCDate(FIRST_SWAP_DATE);

// Convert local date to start of UTC day
export const localDayToUTCDay = (date: Date) =>
  new UTCDate(date.getFullYear(), date.getMonth(), date.getDate());

export const timeframes = [
  {
    name: 'Last 7 days',
    startDate: toStartOfUtcDay(subDays(new Date(), 6)),
    endDate: toEndOfUtcDay(new Date()),
  },
  {
    name: 'Last 14 days',
    startDate: toStartOfUtcDay(subDays(new Date(), 13)),
    endDate: toEndOfUtcDay(new Date()),
  },
  {
    name: 'Last 30 days',
    startDate: toStartOfUtcDay(subDays(new Date(), 29)),
    endDate: toEndOfUtcDay(new Date()),
  },
  {
    name: 'Last 90 days',
    startDate: toStartOfUtcDay(subDays(new Date(), 89)),
    endDate: toEndOfUtcDay(new Date()),
  },
  {
    name: 'All time',
    startDate: minSwapDate,
    endDate: toEndOfUtcDay(new Date()),
  },
];

export const getSelectedTimeframe = (startDate: UTCDate, endDate: UTCDate) =>
  timeframes.find(
    (tf) =>
      tf.startDate.getTime() === startDate.getTime() && tf.endDate.getTime() === endDate.getTime(),
  );

// Date formatting utils
export const getLocaleDateStrings = <T extends Intl.DateTimeFormatOptions>(
  date: Date,
  options: T,
): { [K in keyof T]: string } =>
  Object.fromEntries(
    Object.entries(options).map(([prop, value]) => [
      prop,
      date.toLocaleDateString(undefined, { [prop]: value }),
    ]),
  ) as { [K in keyof T]: string };

export const getDatesLabel = (datesRange: DatesRange) => {
  const dateOptions: Required<
    Pick<Intl.DateTimeFormatOptions, 'day' | 'month' | 'year' | 'timeZone'>
  > = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    timeZone: 'UTC',
  };
  const startDateStrings = getLocaleDateStrings(datesRange[0], dateOptions);
  const endDateStrings = getLocaleDateStrings(datesRange[1], dateOptions);

  const endDate = `${endDateStrings.day} ${endDateStrings.month} ${endDateStrings.year}`;
  let startDate: string;

  if (
    datesRange[0].getUTCMonth() === datesRange[1].getUTCMonth() &&
    datesRange[0].getUTCFullYear() === datesRange[1].getUTCFullYear()
  ) {
    startDate = startDateStrings.day;
  } else if (
    datesRange[0].getUTCMonth() !== datesRange[1].getUTCMonth() &&
    datesRange[0].getUTCFullYear() === datesRange[1].getUTCFullYear()
  ) {
    startDate = `${startDateStrings.day} ${startDateStrings.month}`;
  } else {
    startDate = `${startDateStrings.day} ${startDateStrings.month} ${startDateStrings.year}`;
  }

  return { startDate, endDate };
};

export const getTimeframeOrFormattedDateLabels = (datesRange: DatesRange) => {
  const selectedTimeframe = getSelectedTimeframe(...datesRange);
  if (selectedTimeframe) return selectedTimeframe.name;

  const { startDate: start, endDate: end } = getDatesLabel(datesRange);
  return `${start} - ${end}`;
};
