import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { getChainflipNetwork } from '@/shared/utils';

export const enum SafaryEvent {
  SwapConnectEvent = 'swap_connect_event',
  DepositEvent = 'deposit_event',
}

export type SafarTrackEvents = {
  [SafaryEvent.SwapConnectEvent]: {
    fromAmount: number;
    fromCurrency: string;
    fromAmountUSD: number;
    fromWalletAddress: string;
    fromChain: number | string; // chain id or name
    toAmount: number;
    toCurrency: string;
    toAmountUSD: number;
    toWalletAddress: string;
    toChain: number | string; // chain id or name
  };
  [SafaryEvent.DepositEvent]: {
    walletAddress: string;
    amount: number;
    currency: string;
    amountUSD: number;
    contractAddress: string;
  };
};

type EventType = 'trackSwapConnect' | 'deposit' | 'click' | 'swap';
type Events = { [K: string]: Record<string, unknown> };

export default function useSafary<E extends Events>() {
  return useCallback(<N extends keyof E>(eventName: N, eventType: EventType, props: E[N]) => {
    if (getChainflipNetwork() !== 'mainnet') {
      return;
    }
    try {
      if (eventType === 'trackSwapConnect') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).safary?.trackSwapConnect({
          ...props,
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).safary?.track({
          eventType,
          eventName,
          parameters: {
            ...props,
          },
        });
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }, []);
}
