/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query GetTotalDeployedBoostLiquidity {\n    pools: allBoostPools {\n      aggregates {\n        sum {\n          availableAmountValueUsd\n          unavailableAmountValueUsd\n        }\n      }\n    }\n  }\n": types.GetTotalDeployedBoostLiquidityDocument,
    "\n  query GetBrokerAccountCacheData($idSs58: String!) {\n    broker: brokerAccountByIdSs58(idSs58: $idSs58) {\n      flipBalance\n      idSs58\n      bondedFlipBalance\n      brokerVaultDepositAddress\n      registrationId\n      withdrawalAddress\n      delegateBrokerId\n      affiliates: brokerAccountsByDelegateBrokerId {\n        nodes {\n          registrationId\n          idSs58\n          withdrawalAddress\n        }\n      }\n      feesEarned: brokerAccountEarnedFeesByBrokerAccountIdSs58 {\n        groupedAggregates(groupBy: ASSET) {\n          sum {\n            amount\n          }\n          keys\n        }\n      }\n    }\n  }\n": types.GetBrokerAccountCacheDataDocument,
    "\n  query GetBrokersBalances {\n    brokers: allBrokerAccounts {\n      nodes {\n        flipBalance\n        idSs58\n      }\n    }\n  }\n": types.GetBrokersBalancesDocument,
    "\n  query GetRuntimeVersion {\n    allEnvironmentAddresses {\n      nodes {\n        runtimeVersion\n      }\n    }\n  }\n": types.GetRuntimeVersionDocument,
    "\n  fragment LpAccountWithBalances on LpAccount {\n    idSs58\n    flipBalance\n    alias\n    openOrders\n    earnedFeesValueUsd\n    balances: depositBalancesByLpIdSs58 {\n      nodes {\n        chain\n        asset\n        amount\n      }\n    }\n    boostBalances: boostBalancesByLpIdSs58 {\n      nodes {\n        availableAmount\n        availableAmountValueUsd\n        pool: boostPoolByPoolId {\n          asset\n        }\n        unavailableAmount\n        unavailableAmountValueUsd\n      }\n    }\n  }\n": types.LpAccountWithBalancesFragmentDoc,
    "\n  query GetCacheUndeployedLiquidity($lpIdSs58: String) {\n    depositBalances: allDepositBalances(condition: { lpIdSs58: $lpIdSs58 }) {\n      groupedAggregates(groupBy: ASSET) {\n        sum {\n          amount\n        }\n        keys\n      }\n    }\n  }\n": types.GetCacheUndeployedLiquidityDocument,
    "\n  query GetCirculatingSupply {\n    circulation: circulationById(id: 1) {\n      circulatingSupply\n      totalSupply\n    }\n  }\n": types.GetCirculatingSupplyDocument,
    "\n  query GetEnvironmentAddress {\n    environmentAddress: environmentAddressById(id: 1) {\n      keyManagerAddress\n      stateChainGatewayAddress\n    }\n  }\n": types.GetEnvironmentAddressDocument,
    "\n  query GetValidatorByIdSs58($validatorId: String!) {\n    validators: allValidators(condition: { idSs58: $validatorId }) {\n      nodes {\n        ...CacheValidator\n      }\n    }\n  }\n": types.GetValidatorByIdSs58Document,
    "\n  query GetActiveCacheValidators {\n    validators: allValidators(\n      filter: {\n        or: [{ isCurrentAuthority: { equalTo: true } }, { isCurrentBackup: { equalTo: true } }]\n      }\n    ) {\n      nodes {\n        ...CacheValidator\n      }\n    }\n  }\n": types.GetActiveCacheValidatorsDocument,
    "\n  query getAllBoostPoolsCache {\n    pools: allBoostPools {\n      nodes {\n        id\n        feeTierPips\n        asset\n        availableAmount\n        availableAmountValueUsd\n        unavailableAmount\n        unavailableAmountValueUsd\n        boosters: boostBalancesByPoolId {\n          totalCount\n        }\n      }\n    }\n  }\n": types.GetAllBoostPoolsCacheDocument,
    "\n  query GetBoostPoolCache($chainflipAsset: ChainflipAsset!, $feeTierPips: Int!) {\n    pools: allBoostPools(\n      filter: { feeTierPips: { equalTo: $feeTierPips }, asset: { equalTo: $chainflipAsset } }\n    ) {\n      nodes {\n        id\n        availableAmount\n        unavailableAmount\n        availableAmountValueUsd\n        unavailableAmountValueUsd\n        balances: boostBalancesByPoolId {\n          nodes {\n            lpIdSs58\n            poolShare\n            availableAmount\n            unavailableAmount\n            availableAmountValueUsd\n            unavailableAmountValueUsd\n          }\n        }\n      }\n    }\n  }\n": types.GetBoostPoolCacheDocument,
    "\n  query AllBoostBalancesForAccount($accountId: String!) {\n    balances: allBoostBalances(condition: { lpIdSs58: $accountId }) {\n      nodes {\n        pool: boostPoolByPoolId {\n          id\n          asset\n          feeTierPips\n          availableAmount\n          unavailableAmount\n        }\n        availableAmountValueUsd\n        unavailableAmountValueUsd\n        unavailableAmount\n        availableAmount\n        poolShare\n      }\n    }\n  }\n": types.AllBoostBalancesForAccountDocument,
    "\n  query getPendingDeposits($address: String!, $chain: String!, $asset: String!) {\n    pendingDeposits(input: { address: $address, chain: $chain, asset: $asset }) {\n      amount\n      txRef\n    }\n  }\n": types.GetPendingDepositsDocument,
    "\n  fragment CacheValidator on Validator {\n    idHex\n    idSs58\n    alias\n    apyBp\n    boundRedeemAddress\n    isCurrentAuthority\n    isCurrentBackup\n    isQualified\n    isOnline\n    isBidding\n    isKeyholder\n    reputationPoints\n    lockedBalance\n    unlockedBalance\n  }\n": types.CacheValidatorFragmentDoc,
    "\n  fragment Pool on Pool {\n    id\n    baseAsset\n    quoteAsset\n    rangeOrderPrice\n  }\n": types.PoolFragmentDoc,
    "\n  query GetFilteredLpAccountsWithBalances($filter: String) {\n    lps: allLpAccounts(\n      filter: {\n        or: [\n          { idSs58: { includesInsensitive: $filter } }\n          { alias: { includesInsensitive: $filter } }\n        ]\n      }\n    ) {\n      totalCount\n      nodes {\n        ...LpAccountWithBalances\n      }\n    }\n    pools: allPools {\n      nodes {\n        ...Pool\n      }\n    }\n  }\n": types.GetFilteredLpAccountsWithBalancesDocument,
    "\n  query GetCachePools($baseAsset: ChainflipAsset) {\n    pools: allPools(condition: { baseAsset: $baseAsset }) {\n      nodes {\n        baseAsset\n        quoteAsset\n        buyPrice\n        rangeOrderPrice\n        baseLiquidityAmount\n        quoteLiquidityAmount\n        liquidityFeeHundredthPips\n      }\n    }\n  }\n": types.GetCachePoolsDocument,
    "\n  query GetCachePoolsWithOrder($baseAsset: ChainflipAsset) {\n    pools: allPools(condition: { baseAsset: $baseAsset }) {\n      nodes {\n        baseAsset\n        quoteAsset\n        buyPrice\n        rangeOrderPrice\n        baseLiquidityAmount\n        quoteLiquidityAmount\n        liquidityFeeHundredthPips\n        openOrders\n      }\n    }\n  }\n": types.GetCachePoolsWithOrderDocument,
    "\n  query BannerStatus($app: App!) {\n    banner: allBanners(filter: { or: [{ app: { equalTo: $app } }, { app: { equalTo: ALL } }] }) {\n      nodes {\n        message\n        type\n      }\n    }\n  }\n": types.BannerStatusDocument,
    "\n  query GetLatestAuction {\n    auction: auctionById(id: 1) {\n      minActiveBid\n      startBlockNumber\n      endBlockNumber\n      currentHeight\n      projectedLockup\n      redemptionPeriodAsPercentage\n      targetSetSize\n      authorityRotationEnabled\n    }\n  }\n": types.GetLatestAuctionDocument,
    "\n  query GetAllEnvironmentAddresses {\n    allEnvironmentAddresses {\n      nodes {\n        redemptionTax\n      }\n    }\n  }\n": types.GetAllEnvironmentAddressesDocument,
    "\n  query GetLatestFlipSupplyQuery {\n    flipSupply: flipSupplyById(id: 1) {\n      totalIssuance\n      offchainFunds\n      annualSystemCompoundedRewards\n    }\n  }\n": types.GetLatestFlipSupplyQueryDocument,
    "\n  query Validators {\n    validators: allValidators {\n      nodes {\n        ...CacheValidator\n      }\n    }\n  }\n": types.ValidatorsDocument,
    "\n  query GetValidatorsByAccountIds($idSs58s: [String!]!) {\n    validators: allValidators(filter: { idSs58: { in: $idSs58s } }) {\n      nodes {\n        ...CacheValidator\n      }\n    }\n  }\n": types.GetValidatorsByAccountIdsDocument,
    "\n  query GetLinkedAccounts($ethAddress: String!, $idSs58s: [String!]!) {\n    links: allAccountLinks(condition: { ethereumAddress: $ethAddress }) {\n      nodes {\n        accountIdSs58\n      }\n    }\n    unregistered: allUnregistereds(filter: { idSs58: { in: $idSs58s } }) {\n      nodes {\n        idSs58\n      }\n    }\n  }\n": types.GetLinkedAccountsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTotalDeployedBoostLiquidity {\n    pools: allBoostPools {\n      aggregates {\n        sum {\n          availableAmountValueUsd\n          unavailableAmountValueUsd\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTotalDeployedBoostLiquidity {\n    pools: allBoostPools {\n      aggregates {\n        sum {\n          availableAmountValueUsd\n          unavailableAmountValueUsd\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBrokerAccountCacheData($idSs58: String!) {\n    broker: brokerAccountByIdSs58(idSs58: $idSs58) {\n      flipBalance\n      idSs58\n      bondedFlipBalance\n      brokerVaultDepositAddress\n      registrationId\n      withdrawalAddress\n      delegateBrokerId\n      affiliates: brokerAccountsByDelegateBrokerId {\n        nodes {\n          registrationId\n          idSs58\n          withdrawalAddress\n        }\n      }\n      feesEarned: brokerAccountEarnedFeesByBrokerAccountIdSs58 {\n        groupedAggregates(groupBy: ASSET) {\n          sum {\n            amount\n          }\n          keys\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetBrokerAccountCacheData($idSs58: String!) {\n    broker: brokerAccountByIdSs58(idSs58: $idSs58) {\n      flipBalance\n      idSs58\n      bondedFlipBalance\n      brokerVaultDepositAddress\n      registrationId\n      withdrawalAddress\n      delegateBrokerId\n      affiliates: brokerAccountsByDelegateBrokerId {\n        nodes {\n          registrationId\n          idSs58\n          withdrawalAddress\n        }\n      }\n      feesEarned: brokerAccountEarnedFeesByBrokerAccountIdSs58 {\n        groupedAggregates(groupBy: ASSET) {\n          sum {\n            amount\n          }\n          keys\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBrokersBalances {\n    brokers: allBrokerAccounts {\n      nodes {\n        flipBalance\n        idSs58\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetBrokersBalances {\n    brokers: allBrokerAccounts {\n      nodes {\n        flipBalance\n        idSs58\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRuntimeVersion {\n    allEnvironmentAddresses {\n      nodes {\n        runtimeVersion\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetRuntimeVersion {\n    allEnvironmentAddresses {\n      nodes {\n        runtimeVersion\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment LpAccountWithBalances on LpAccount {\n    idSs58\n    flipBalance\n    alias\n    openOrders\n    earnedFeesValueUsd\n    balances: depositBalancesByLpIdSs58 {\n      nodes {\n        chain\n        asset\n        amount\n      }\n    }\n    boostBalances: boostBalancesByLpIdSs58 {\n      nodes {\n        availableAmount\n        availableAmountValueUsd\n        pool: boostPoolByPoolId {\n          asset\n        }\n        unavailableAmount\n        unavailableAmountValueUsd\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment LpAccountWithBalances on LpAccount {\n    idSs58\n    flipBalance\n    alias\n    openOrders\n    earnedFeesValueUsd\n    balances: depositBalancesByLpIdSs58 {\n      nodes {\n        chain\n        asset\n        amount\n      }\n    }\n    boostBalances: boostBalancesByLpIdSs58 {\n      nodes {\n        availableAmount\n        availableAmountValueUsd\n        pool: boostPoolByPoolId {\n          asset\n        }\n        unavailableAmount\n        unavailableAmountValueUsd\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCacheUndeployedLiquidity($lpIdSs58: String) {\n    depositBalances: allDepositBalances(condition: { lpIdSs58: $lpIdSs58 }) {\n      groupedAggregates(groupBy: ASSET) {\n        sum {\n          amount\n        }\n        keys\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCacheUndeployedLiquidity($lpIdSs58: String) {\n    depositBalances: allDepositBalances(condition: { lpIdSs58: $lpIdSs58 }) {\n      groupedAggregates(groupBy: ASSET) {\n        sum {\n          amount\n        }\n        keys\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCirculatingSupply {\n    circulation: circulationById(id: 1) {\n      circulatingSupply\n      totalSupply\n    }\n  }\n"): (typeof documents)["\n  query GetCirculatingSupply {\n    circulation: circulationById(id: 1) {\n      circulatingSupply\n      totalSupply\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetEnvironmentAddress {\n    environmentAddress: environmentAddressById(id: 1) {\n      keyManagerAddress\n      stateChainGatewayAddress\n    }\n  }\n"): (typeof documents)["\n  query GetEnvironmentAddress {\n    environmentAddress: environmentAddressById(id: 1) {\n      keyManagerAddress\n      stateChainGatewayAddress\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetValidatorByIdSs58($validatorId: String!) {\n    validators: allValidators(condition: { idSs58: $validatorId }) {\n      nodes {\n        ...CacheValidator\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetValidatorByIdSs58($validatorId: String!) {\n    validators: allValidators(condition: { idSs58: $validatorId }) {\n      nodes {\n        ...CacheValidator\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetActiveCacheValidators {\n    validators: allValidators(\n      filter: {\n        or: [{ isCurrentAuthority: { equalTo: true } }, { isCurrentBackup: { equalTo: true } }]\n      }\n    ) {\n      nodes {\n        ...CacheValidator\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetActiveCacheValidators {\n    validators: allValidators(\n      filter: {\n        or: [{ isCurrentAuthority: { equalTo: true } }, { isCurrentBackup: { equalTo: true } }]\n      }\n    ) {\n      nodes {\n        ...CacheValidator\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAllBoostPoolsCache {\n    pools: allBoostPools {\n      nodes {\n        id\n        feeTierPips\n        asset\n        availableAmount\n        availableAmountValueUsd\n        unavailableAmount\n        unavailableAmountValueUsd\n        boosters: boostBalancesByPoolId {\n          totalCount\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getAllBoostPoolsCache {\n    pools: allBoostPools {\n      nodes {\n        id\n        feeTierPips\n        asset\n        availableAmount\n        availableAmountValueUsd\n        unavailableAmount\n        unavailableAmountValueUsd\n        boosters: boostBalancesByPoolId {\n          totalCount\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBoostPoolCache($chainflipAsset: ChainflipAsset!, $feeTierPips: Int!) {\n    pools: allBoostPools(\n      filter: { feeTierPips: { equalTo: $feeTierPips }, asset: { equalTo: $chainflipAsset } }\n    ) {\n      nodes {\n        id\n        availableAmount\n        unavailableAmount\n        availableAmountValueUsd\n        unavailableAmountValueUsd\n        balances: boostBalancesByPoolId {\n          nodes {\n            lpIdSs58\n            poolShare\n            availableAmount\n            unavailableAmount\n            availableAmountValueUsd\n            unavailableAmountValueUsd\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetBoostPoolCache($chainflipAsset: ChainflipAsset!, $feeTierPips: Int!) {\n    pools: allBoostPools(\n      filter: { feeTierPips: { equalTo: $feeTierPips }, asset: { equalTo: $chainflipAsset } }\n    ) {\n      nodes {\n        id\n        availableAmount\n        unavailableAmount\n        availableAmountValueUsd\n        unavailableAmountValueUsd\n        balances: boostBalancesByPoolId {\n          nodes {\n            lpIdSs58\n            poolShare\n            availableAmount\n            unavailableAmount\n            availableAmountValueUsd\n            unavailableAmountValueUsd\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AllBoostBalancesForAccount($accountId: String!) {\n    balances: allBoostBalances(condition: { lpIdSs58: $accountId }) {\n      nodes {\n        pool: boostPoolByPoolId {\n          id\n          asset\n          feeTierPips\n          availableAmount\n          unavailableAmount\n        }\n        availableAmountValueUsd\n        unavailableAmountValueUsd\n        unavailableAmount\n        availableAmount\n        poolShare\n      }\n    }\n  }\n"): (typeof documents)["\n  query AllBoostBalancesForAccount($accountId: String!) {\n    balances: allBoostBalances(condition: { lpIdSs58: $accountId }) {\n      nodes {\n        pool: boostPoolByPoolId {\n          id\n          asset\n          feeTierPips\n          availableAmount\n          unavailableAmount\n        }\n        availableAmountValueUsd\n        unavailableAmountValueUsd\n        unavailableAmount\n        availableAmount\n        poolShare\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getPendingDeposits($address: String!, $chain: String!, $asset: String!) {\n    pendingDeposits(input: { address: $address, chain: $chain, asset: $asset }) {\n      amount\n      txRef\n    }\n  }\n"): (typeof documents)["\n  query getPendingDeposits($address: String!, $chain: String!, $asset: String!) {\n    pendingDeposits(input: { address: $address, chain: $chain, asset: $asset }) {\n      amount\n      txRef\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CacheValidator on Validator {\n    idHex\n    idSs58\n    alias\n    apyBp\n    boundRedeemAddress\n    isCurrentAuthority\n    isCurrentBackup\n    isQualified\n    isOnline\n    isBidding\n    isKeyholder\n    reputationPoints\n    lockedBalance\n    unlockedBalance\n  }\n"): (typeof documents)["\n  fragment CacheValidator on Validator {\n    idHex\n    idSs58\n    alias\n    apyBp\n    boundRedeemAddress\n    isCurrentAuthority\n    isCurrentBackup\n    isQualified\n    isOnline\n    isBidding\n    isKeyholder\n    reputationPoints\n    lockedBalance\n    unlockedBalance\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Pool on Pool {\n    id\n    baseAsset\n    quoteAsset\n    rangeOrderPrice\n  }\n"): (typeof documents)["\n  fragment Pool on Pool {\n    id\n    baseAsset\n    quoteAsset\n    rangeOrderPrice\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFilteredLpAccountsWithBalances($filter: String) {\n    lps: allLpAccounts(\n      filter: {\n        or: [\n          { idSs58: { includesInsensitive: $filter } }\n          { alias: { includesInsensitive: $filter } }\n        ]\n      }\n    ) {\n      totalCount\n      nodes {\n        ...LpAccountWithBalances\n      }\n    }\n    pools: allPools {\n      nodes {\n        ...Pool\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetFilteredLpAccountsWithBalances($filter: String) {\n    lps: allLpAccounts(\n      filter: {\n        or: [\n          { idSs58: { includesInsensitive: $filter } }\n          { alias: { includesInsensitive: $filter } }\n        ]\n      }\n    ) {\n      totalCount\n      nodes {\n        ...LpAccountWithBalances\n      }\n    }\n    pools: allPools {\n      nodes {\n        ...Pool\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCachePools($baseAsset: ChainflipAsset) {\n    pools: allPools(condition: { baseAsset: $baseAsset }) {\n      nodes {\n        baseAsset\n        quoteAsset\n        buyPrice\n        rangeOrderPrice\n        baseLiquidityAmount\n        quoteLiquidityAmount\n        liquidityFeeHundredthPips\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCachePools($baseAsset: ChainflipAsset) {\n    pools: allPools(condition: { baseAsset: $baseAsset }) {\n      nodes {\n        baseAsset\n        quoteAsset\n        buyPrice\n        rangeOrderPrice\n        baseLiquidityAmount\n        quoteLiquidityAmount\n        liquidityFeeHundredthPips\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCachePoolsWithOrder($baseAsset: ChainflipAsset) {\n    pools: allPools(condition: { baseAsset: $baseAsset }) {\n      nodes {\n        baseAsset\n        quoteAsset\n        buyPrice\n        rangeOrderPrice\n        baseLiquidityAmount\n        quoteLiquidityAmount\n        liquidityFeeHundredthPips\n        openOrders\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCachePoolsWithOrder($baseAsset: ChainflipAsset) {\n    pools: allPools(condition: { baseAsset: $baseAsset }) {\n      nodes {\n        baseAsset\n        quoteAsset\n        buyPrice\n        rangeOrderPrice\n        baseLiquidityAmount\n        quoteLiquidityAmount\n        liquidityFeeHundredthPips\n        openOrders\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query BannerStatus($app: App!) {\n    banner: allBanners(filter: { or: [{ app: { equalTo: $app } }, { app: { equalTo: ALL } }] }) {\n      nodes {\n        message\n        type\n      }\n    }\n  }\n"): (typeof documents)["\n  query BannerStatus($app: App!) {\n    banner: allBanners(filter: { or: [{ app: { equalTo: $app } }, { app: { equalTo: ALL } }] }) {\n      nodes {\n        message\n        type\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetLatestAuction {\n    auction: auctionById(id: 1) {\n      minActiveBid\n      startBlockNumber\n      endBlockNumber\n      currentHeight\n      projectedLockup\n      redemptionPeriodAsPercentage\n      targetSetSize\n      authorityRotationEnabled\n    }\n  }\n"): (typeof documents)["\n  query GetLatestAuction {\n    auction: auctionById(id: 1) {\n      minActiveBid\n      startBlockNumber\n      endBlockNumber\n      currentHeight\n      projectedLockup\n      redemptionPeriodAsPercentage\n      targetSetSize\n      authorityRotationEnabled\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAllEnvironmentAddresses {\n    allEnvironmentAddresses {\n      nodes {\n        redemptionTax\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAllEnvironmentAddresses {\n    allEnvironmentAddresses {\n      nodes {\n        redemptionTax\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetLatestFlipSupplyQuery {\n    flipSupply: flipSupplyById(id: 1) {\n      totalIssuance\n      offchainFunds\n      annualSystemCompoundedRewards\n    }\n  }\n"): (typeof documents)["\n  query GetLatestFlipSupplyQuery {\n    flipSupply: flipSupplyById(id: 1) {\n      totalIssuance\n      offchainFunds\n      annualSystemCompoundedRewards\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Validators {\n    validators: allValidators {\n      nodes {\n        ...CacheValidator\n      }\n    }\n  }\n"): (typeof documents)["\n  query Validators {\n    validators: allValidators {\n      nodes {\n        ...CacheValidator\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetValidatorsByAccountIds($idSs58s: [String!]!) {\n    validators: allValidators(filter: { idSs58: { in: $idSs58s } }) {\n      nodes {\n        ...CacheValidator\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetValidatorsByAccountIds($idSs58s: [String!]!) {\n    validators: allValidators(filter: { idSs58: { in: $idSs58s } }) {\n      nodes {\n        ...CacheValidator\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetLinkedAccounts($ethAddress: String!, $idSs58s: [String!]!) {\n    links: allAccountLinks(condition: { ethereumAddress: $ethAddress }) {\n      nodes {\n        accountIdSs58\n      }\n    }\n    unregistered: allUnregistereds(filter: { idSs58: { in: $idSs58s } }) {\n      nodes {\n        idSs58\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetLinkedAccounts($ethAddress: String!, $idSs58s: [String!]!) {\n    links: allAccountLinks(condition: { ethereumAddress: $ethAddress }) {\n      nodes {\n        accountIdSs58\n      }\n    }\n    unregistered: allUnregistereds(filter: { idSs58: { in: $idSs58s } }) {\n      nodes {\n        idSs58\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;