import { gql } from '@/shared/graphql/generated/explorer';

export const getMarketData = gql(/* GraphQL */ `
  query GetMarketData($asset: ChainflipAsset!, $numberOfDays: Int!) {
    marketData(asset: $asset, numberOfDays: $numberOfDays) {
      candlesticks {
        ... on CandlestickData {
          time
          open
          low
          high
          close
        }
        ... on WhitespaceData {
          time
        }
      }
      volumes {
        ... on HistogramData {
          value
          time
        }
        ... on WhitespaceData {
          time
        }
      }
      prices {
        time
        value
      }
      globalVolume
    }
  }
`);

export const getChainTrackingQuery = gql(/* GraphQL */ `
  query GetChainTrackingQuery {
    allChainTrackings {
      nodes {
        chain
        height
      }
    }
  }
`);

export const getLatestEpochsQuery = gql(/* GraphQL */ `
  query GetLatestEpochs($limit: Int!) {
    latestEpoch: allEpoches(first: $limit, orderBy: ID_DESC) {
      nodes {
        ...EpochWithRotation
      }
    }
  }
`);

export const getLatestBlocksQuery = gql(/* GraphQL */ `
  query GetLatestBlocks($limit: Int! = 10) {
    latestBlocks: allBlocks(first: $limit, orderBy: ID_DESC) {
      nodes {
        ...BlockListBlock
      }
    }
  }
`);
