import { useState } from 'react';
import { useWindowEventListener } from '@/shared/hooks';

const MOBILE_SIZE = 767;
const TABLET_SIZE = 1023;

const useScreenSize = (customSize?: number) => {
  if (typeof window === 'undefined')
    return { isMobile: false, isTablet: false, isCustomSize: false };
  const [width, setWidth] = useState(() => window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useWindowEventListener('resize', handleWindowSizeChange);

  return {
    isMobile: width <= MOBILE_SIZE,
    isTablet: width <= TABLET_SIZE,
    isCustomSize: customSize !== undefined && width <= customSize,
  };
};

export default useScreenSize;
