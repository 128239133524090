import { useMemo, useState } from 'react';
import { toEndOfUtcDay, toStartOfUtcDay } from '@chainflip/utils/date';
import { differenceInDays, subDays } from 'date-fns';
import { FIRST_SWAP_DATE } from '../constants';

export default function useTimeframeSelection<T extends { value: number }>(
  options: T[],
  defaultSelection: T = options[options.length ? options.length - 1 : 0],
) {
  const [selectedTimeframe, setSelectedTimeframe] = useState(defaultSelection);
  const [chartStartDate, chartEndDate] = useMemo(
    () => [
      toStartOfUtcDay(subDays(new Date(), selectedTimeframe.value)),
      toEndOfUtcDay(new Date()),
    ],
    [selectedTimeframe],
  );
  const daysBack = differenceInDays(chartEndDate, chartStartDate);
  const allTimeDays = differenceInDays(new Date(), new Date(FIRST_SWAP_DATE));

  return {
    selectedTimeframe,
    setSelectedTimeframe,
    chartStartDate,
    chartEndDate,
    daysBack,
    isDaily: daysBack === 1,
    isAllTime: daysBack === allTimeDays,
  };
}
