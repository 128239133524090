import { type ChainflipAsset, type BaseChainflipAsset } from '@chainflip/utils/chainflip';
import BigNumber from 'bignumber.js';
import { chainflipAssetMap } from './env';
import { type OpenOrdersCacheResult } from './schemas';
import { liquidityToTokenAmounts } from './tickMath';
import TokenAmount from './TokenAmount';
import { type PoolOrderFragment } from '../graphql/generated/explorer/graphql';
import { type useChainflipAssetPrices } from '../hooks';

export type LimitOrderType = 'Sell' | 'Buy';

export const accountOrdersToLiquidityUsd = (
  orders: Pick<OpenOrdersCacheResult['orders'], 'limit_orders' | 'range_orders'>,
  baseAsset: BaseChainflipAsset,
  quoteAsset: ChainflipAsset,
  baseAssetUsdPrice: number,
  quoteAssetUsdPrice: number,
  currentPoolRate: number,
) => {
  const baseToken = chainflipAssetMap[baseAsset];
  const quoteToken = chainflipAssetMap[quoteAsset];

  let usdHoldings = 0;

  for (const order of orders.range_orders) {
    const tokenAmounts = liquidityToTokenAmounts({
      liquidity: order.liquidity.toString(),
      currentRate: currentPoolRate,
      lowerTick: order.range.start,
      upperTick: order.range.end,
      baseAsset: baseAsset as BaseChainflipAsset,
    });

    usdHoldings += quoteAssetUsdPrice * tokenAmounts.quoteAsset.toNumber();
    usdHoldings += baseAssetUsdPrice * tokenAmounts.baseAsset.toNumber();
  }

  for (const order of orders.limit_orders.asks) {
    const baseTokenAmount = new TokenAmount(order.sell_amount, baseToken.decimals);
    usdHoldings += baseAssetUsdPrice * baseTokenAmount.toNumber();
  }

  for (const order of orders.limit_orders.bids) {
    const quoteTokenAmount = new TokenAmount(order.sell_amount, quoteToken.decimals);
    usdHoldings += quoteAssetUsdPrice * quoteTokenAmount.toNumber();
  }

  return usdHoldings;
};

export type RpcOrder = ReturnType<typeof transformCacheOrdersToRpcOrders>[number];

export const transformCacheOrdersToRpcOrders = (orders: OpenOrdersCacheResult[]) =>
  orders
    .map((item) => [
      ...item.orders.limit_orders.asks.map((order) => ({
        ...order,
        asset: item.baseAsset,
        type: 'ask' as const,
      })),
      ...item.orders.limit_orders.bids.map((order) => ({
        ...order,
        asset: item.baseAsset,
        type: 'bid' as const,
      })),
      ...item.orders.range_orders.map((order) => ({
        ...order,
        asset: item.baseAsset,
        type: 'range' as const,
      })),
    ])
    .flat();

export const addValueToRpcOrders = ({
  rpcOrders,
  processorOrders,
  poolData,
  assetPrices,
}: {
  rpcOrders: RpcOrder[];
  processorOrders?: PoolOrderFragment[];
  poolData: { baseAsset: string; rangeOrderPrice: number }[] | undefined;
  assetPrices: ReturnType<typeof useChainflipAssetPrices>['prices'];
}) =>
  rpcOrders.map((order) => {
    if (order.type === 'range') {
      const currentRangeOrderPrice = poolData?.find((item) => item.baseAsset === order.asset)
        ?.rangeOrderPrice;

      const tokenAmounts = currentRangeOrderPrice
        ? liquidityToTokenAmounts({
            liquidity: order.liquidity.toString(),
            baseAsset: order.asset,
            currentRate: currentRangeOrderPrice,
            lowerTick: order.range.start,
            upperTick: order.range.end,
          })
        : undefined;
      return {
        ...order,
        createdAt: processorOrders?.find(
          (processorOrder) =>
            processorOrder.orderType === 'RANGE' &&
            processorOrder.orderId === order.id &&
            processorOrder.baseAsset === order.asset,
        )?.event.block.timestamp,
        valueUsd:
          tokenAmounts?.baseAsset
            .toBigNumber()
            .multipliedBy(assetPrices?.[order.asset] ?? 0)
            .plus(tokenAmounts.quoteAsset.mul(assetPrices?.Usdc ?? 0).toBigNumber())
            .toFixed(2) ?? '',
      };
    }

    return {
      ...order,
      createdAt: processorOrders?.find(
        (processorOrder) =>
          processorOrder.orderType === 'LIMIT' &&
          processorOrder.orderId === order.id &&
          processorOrder.baseAsset === order.asset,
      )?.event.block.timestamp,
      valueUsd:
        order.type === 'bid'
          ? TokenAmount.fromAsset(order.original_sell_amount, 'Usdc')
              .mul(assetPrices?.Usdc ?? 0)
              .toBigNumber()
              .toFixed(2)
          : TokenAmount.fromAsset(
              new BigNumber(order.original_sell_amount.toString() ?? 0)
                .multipliedBy(assetPrices?.[order.asset] ?? 0)
                .toFixed(),
              order.asset,
            )
              .toBigNumber()
              .toFixed(2),
    };
  });
