import { useQuery } from '@tanstack/react-query';
import { lpProcessorClient } from '../graphql/client';
import { getLpProcessorLpIdQuery } from '../graphql/lp/lp';

export const useLpProcessorLpId = (idSs58: string | null | undefined) => {
  const { data: lpId = null } = useQuery({
    queryKey: ['LP_ID', idSs58],
    queryFn: () => lpProcessorClient.request(getLpProcessorLpIdQuery, { idSs58: idSs58 as string }),
    select: (data) => data.account?.id,
    enabled: Boolean(idSs58),
  });

  return lpId;
};
