import { useMemo } from 'react';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import { getLatestEpochsQuery } from '../graphql/explorer/queries';
import { type EpochWithRotationFragment } from '../graphql/generated/explorer/graphql';

type EpochHook = {
  epochsWithRotation: EpochWithRotationFragment[] | null;
  isLoading: boolean;
  error?: Error;
};

export default function useEpochsWithRotation(options?: { pollInterval?: number }): EpochHook {
  const result = useGqlQuery(getLatestEpochsQuery, {
    variables: { limit: 2 },
    context: { clientName: 'processor' },
    refetchInterval: options?.pollInterval,
  });

  return useMemo(() => {
    let epochInfo = null;

    const { data, isLoading, error } = result;

    if (data?.latestEpoch?.nodes.length) {
      epochInfo = data.latestEpoch.nodes;
    }

    return { epochsWithRotation: epochInfo, isLoading, error: error ?? undefined };
  }, [result]);
}
