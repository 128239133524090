import { forwardRef, type MouseEventHandler } from 'react';
import classNames from 'classnames';
import spinnerBlack from '../../animations/spinner-black.json';
import spinnerWhite from '../../animations/spinner-white.json';
import ArrowLarge from '../../icons/flip-ui-kit/large/Arrow';
import ArrowSmall from '../../icons/flip-ui-kit/small/Arrow';
import Lottie from '../../utils/Lottie';

type ButtonType =
  | 'primary-standard'
  | 'primary-danger'
  | 'primary-ghost'
  | 'secondary-standard'
  | 'secondary-danger'
  | 'secondary-ghost'
  | 'link-standard'
  | 'link-secondary'
  | 'link-danger';

type StyleMap = Record<ButtonType, string>;

const sizeStyles = {
  small: 'min-w-[89px] h-[32px] p-[8px] text-12',
  medium: 'min-w-[116px] h-[40px] py-[12px] px-[16px] text-14',
  large: 'min-w-[148px] h-[48px] py-[16px] px-[32px] text-16',
};

export const baseStyles: StyleMap = {
  'primary-standard':
    'text-black border border-[#6ACAA2] [background:linear-gradient(180deg,rgba(255,255,255,0.2)_0%,rgba(255,255,255,0)_100%),#3dbf88] shadow-[0px_1px_2px_0px_rgba(29,116,90,0.40),0px_0px_0px_1.5px_rgba(36,255,163,0.36)]',
  'primary-danger':
    'text-black border border-[#E16868] [background:linear-gradient(180deg,rgba(255,255,255,0.06)_0%,rgba(255,255,255,0)_100%),#E44040] shadow-[0px_1px_2px_0px_rgba(116,29,29,0.40),0px_0px_0px_1.5px_rgba(255,36,36,0.36)]',
  'primary-ghost': 'text-cf-green-1 border border-cf-green-1',
  'secondary-standard':
    'text-cf-light-3 border border-[#252525] bg-[#1D1D1D] shadow-[inset_3px_3px_18px_0px_rgba(0,0,0,0.16),0px_0px_0px_2px_rgba(11,11,11,0.30)] rounded-[6px]',
  'secondary-danger': 'text-cf-red-1 bg-cf-red-4 border border-cf-red-1/20',
  'secondary-ghost': 'text-cf-white border border-cf-white',
  'link-standard': 'text-cf-green-1',
  'link-secondary': 'text-cf-light-3',
  'link-danger': 'text-cf-red-1',
};

export const hoverStyles: StyleMap = {
  'primary-standard':
    'hover:enabled:[background:linear-gradient(180deg,rgba(255,255,255,0.3)_0%,rgba(255,255,255,0)_100%),#54e4a7] hover:enabled:shadow-[0px_1px_2px_0px_rgba(29,116,90,0.40),0px_0px_0px_1.5px_rgba(36,255,163,0.36)]',
  // the transition for primary-danger is not working for some reason (i'm out of ideas if anyone else wants to take a crack at it)
  'primary-danger':
    'hover:enabled:[background:linear-gradient(180deg,rgba(255,255,255,0.16)_0%,rgba(255,255,255,0)_100%),#E44040] hover:enabled:shadow-[0px_1px_2px_0px_rgba(116,29,29,0.40),0px_0px_0px_1.5px_rgba(255,36,36,0.36)]',
  'primary-ghost': 'hover:text-cf-green-3 hover:border-cf-green-3',
  'secondary-standard':
    'hover:enabled:rounded-[6px] hover:enabled:border-[1px] hover:enabled:border-[var(--Gray-5,#424242)] hover:enabled:[background:var(--Gray-4,#252525)] hover:enabled:shadow-[0px_0px_0px_2px_rgba(11,11,11,0.30),3px_3px_18px_0px_rgba(0,0,0,0.16)_inset]',
  'secondary-danger': 'hover:enabled:text-cf-red-2 border border-cf-red-1/20',
  'secondary-ghost': 'hover:enabled:text-cf-light-3 hover:enabled:border-cf-light-3',

  'link-standard': 'hover:enabled:text-cf-green-3',
  'link-secondary': 'hover:enabled:text-cf-white',
  'link-danger': 'hover:enabled:text-cf-red-2',
};

export const disabledStyles: StyleMap = {
  'primary-standard':
    'disabled:text-cf-gray-5 disabled:[background:#1D1D1D] disabled:border-cf-gray-4 disabled:shadow-[unset]',
  'primary-danger':
    'disabled:text-cf-gray-5 disabled:[background:#1D1D1D] disabled:border-cf-gray-4 disabled:shadow-[unset]',
  'primary-ghost': 'disabled:text-cf-gray-5 disabled:border disabled:border-cf-gray-5',
  'secondary-standard':
    'disabled:text-cf-gray-5 disabled:[background:#1D1D1D] disabled:shadow-[3px_3px_18px_0px_rgba(0,0,0,0.16)_inset,0px_0px_0px_2px_rgba(11,11,11,0.16)]',
  'secondary-danger': 'disabled:text-cf-gray-5 disabled:[background:#1D1D1D] disabled:border-none',
  'secondary-ghost': 'disabled:text-cf-gray-5 disabled:border disabled:border-cf-gray-5',

  'link-standard': 'disabled:text-cf-gray-5',
  'link-secondary': 'disabled:text-cf-gray-5',
  'link-danger': 'disabled:text-cf-gray-5',
};

export type ButtonProps = {
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: ButtonType;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  iconPos?: 'left' | 'right';
  loading?: 'iconOnly' | boolean | undefined;
  fullWidth?: boolean;
  className?: string;
  icon?: JSX.Element;
  loadingText?: string;
};

const ArrowIcon = ({ size, className }: { size: ButtonProps['size']; className: string }) =>
  size === 'small' ? (
    <ArrowSmall className={className} direction="right" />
  ) : (
    <ArrowLarge className={className} direction="right" />
  );

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'primary-standard',
      size = 'medium',
      disabled = false,
      onClick,
      iconPos,
      loading,
      fullWidth,
      children,
      className,
      icon,
      loadingText = 'Loading...',
      ...rest
    },
    ref,
  ) => (
    <button
      ref={ref}
      type="button"
      disabled={disabled}
      aria-disabled={disabled}
      className={classNames(
        'group flex',
        'items-center',
        'justify-center',
        'rounded-md',
        'font-aeonikMedium',
        'transition duration-150',
        sizeStyles[size],
        baseStyles[type],
        hoverStyles[type],
        disabledStyles[type],
        fullWidth && 'w-full justify-center',
        'hover:disabled:cursor-default',
        'hover:cursor-pointer',
        className,
      )}
      onClick={onClick}
      {...rest}
    >
      {!loading && iconPos === 'left' && (
        <>
          {icon || (
            <ArrowIcon
              size={size}
              className={classNames(size === 'small' ? 'mr-[4px]' : 'mr-[8x]')}
            />
          )}
          {children}
        </>
      )}
      {!loading && iconPos === 'right' && (
        <>
          {children}
          {icon || (
            <ArrowIcon
              size={size}
              className={classNames(size === 'small' ? 'ml-[4px]' : 'ml-[8x]')}
            />
          )}
        </>
      )}
      {loading && (
        <>
          <Lottie
            as="div"
            animationData={
              type.includes('ghost') || disabled || type.includes('secondary')
                ? spinnerWhite
                : spinnerBlack
            }
            autoplay
            loop
            className="h-[18px] w-[18px]"
          />
          {loading !== 'iconOnly' && (
            <span className={classNames('ml-2', size === 'large' ? 'text-16' : 'text-14')}>
              {loadingText}
            </span>
          )}
        </>
      )}
      {!iconPos && !loading && children}
    </button>
  ),
);

export default Button;
