import Router from 'next/router';
import Button from '../flip-ui-kit/Button';

type FallbackLayoutAttributes = {
  message: string;
  onClose: () => void;
};

const FallbackLayout = ({ message, onClose }: FallbackLayoutAttributes): JSX.Element => {
  const routeToHome = async () => {
    await Router.push('/');
    onClose();
  };
  return (
    <div className="flex h-screen w-full items-center justify-center ">
      <div className="space-y-14 text-center">
        <p className="font-aeonikBold text-28">Error</p>
        <div>
          <p className=" text-cf-light-2">
            An unexpected error has occured on our end. Please try again later.
          </p>
          <p className=" text-orange-400">{message}</p>
        </div>
        <div className="flex justify-center">
          <Button onClick={() => Router.reload()} type="secondary-ghost" className="mr-4">
            Refresh
          </Button>
          <Button className="ml-4" onClick={() => routeToHome()}>
            Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FallbackLayout;
