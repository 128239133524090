import { CHAINFLIP_SS58_PREFIX } from '@chainflip/utils/consts';
import * as ss58 from '@chainflip/utils/ss58';
import * as Sentry from '@sentry/react';
import BigNumber from 'bignumber.js';
import { type CastCacheValidatorFragment } from '@/shared/graphql/cache/fragments';
import { type TokenAmount } from '@/shared/utils/index';

export const etherscanUrl = (): string => {
  switch (Number(process.env.NEXT_PUBLIC_ETHEREUM_NETWORK_ID)) {
    case 1:
      return `https://etherscan.io`;
    case 11155111:
      return `https://sepolia.etherscan.io`;
    default:
      return `https://etherscan.io`;
  }
};

export const arbiscanUrl = (): string => {
  switch (Number(process.env.NEXT_PUBLIC_ARBITRUM_NETWORK_ID)) {
    case 42161:
      return `https://arbiscan.io`;
    case 421614:
      return `https://sepolia.arbiscan.io`;
    default:
      return `https://arbiscan.io`;
  }
};

export const solscanUrl = (urlPath: string): string => {
  switch (process.env.NEXT_PUBLIC_CHAINFLIP_NETWORK) {
    case 'mainnet':
      return new URL(urlPath, 'https://solscan.io').toString();
    default:
      return new URL(`${urlPath}?cluster=devnet`, 'https://explorer.solana.com').toString();
  }
};

type ComparatorArg = string | number | BigNumber | bigint;

export type Comparator = Record<'ASC' | 'DESC', (a: ComparatorArg, b: ComparatorArg) => 1 | 0 | -1>;

const compareAscending = (a: ComparatorArg, b: ComparatorArg): 1 | 0 | -1 => {
  if (BigNumber.isBigNumber(a) && BigNumber.isBigNumber(b)) {
    return (a.comparedTo(b) as 1 | 0 | -1) ?? 0;
  }
  if (a < b) return -1;
  if (a === b) return 0;
  return 1;
};

export const COMPARATORS: Comparator = {
  ASC: compareAscending,
  DESC: (a: ComparatorArg, b: ComparatorArg) => (compareAscending(a, b) * -1) as -1 | 0 | 1,
};

export const copy = (text: string): Promise<boolean> =>
  navigator.clipboard
    .writeText(text)
    .then(() => true)
    .catch(() => false);

// NOT a strict check. Works for ss58 but not for hex. eg: ethereum address will return true
export const isChainflipSs58Address = (text: string): boolean => {
  try {
    return ss58.decode(text).ss58Format === CHAINFLIP_SS58_PREFIX;
  } catch (err) {
    return false;
  }
};

// Rule that tries to find a match between any of the event stack frames and the provided list upon creation
const hasMatchingStackframesFactory: (
  stackframes: (string | RegExp)[],
) => (error: Sentry.ErrorEvent) => boolean = (stackframes) => (event) => {
  // if the event has an exception
  if (event.exception?.values && event.exception.values.length > 0) {
    for (const exception of event.exception.values) {
      const stacktraceFrames = exception.stacktrace?.frames;

      // and it has stacktrace frames
      if (stacktraceFrames && stacktraceFrames.length > 0) {
        // check if any of the rule-provided stack frames match the stack frame names
        const framesMatch = stacktraceFrames.some((frame) =>
          stackframes.some((source) => frame.filename?.match(source)),
        );

        if (framesMatch) {
          return true;
        }
      }
    }
  }
  return false;
};

export const initSentry = () => {
  if (!process.env.NEXT_PUBLIC_SENTRY_DSN) return;

  const hasMatchingStackframesCheck = hasMatchingStackframesFactory([
    /walletconnect/gi,
    /xdefi/gi,
    /wagmi/gi,
    /viem/gi,
    /coinbase/gi,
    /moz-extension:\/\//gi,
    /chrome-extension:\/\//gi,
    /sentry-internal/gi,
  ]);

  try {
    Sentry.init({
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.extraErrorDataIntegration({ depth: 5 }),
        Sentry.sessionTimingIntegration(),
      ],
      tracesSampleRate: Number(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE) || undefined,
      allowUrls: [/chainflip\.io/],
      release: process.env.NEXT_PUBLIC_RELEASE_VERSION,
      tunnel: '/sentry',
      ignoreErrors: [/(network|fetch|axios) ?error/i, /xdefi/i, /walletconnect/i],
      // Returning null in case of a rule match will prevent the event from being sent
      beforeSend: (event) => (hasMatchingStackframesCheck(event) ? null : event),
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('failed to initialize sentry:', error);
  }
};

export const noop = () => {
  // pass
};

// for tests only, do not use in production
/* eslint-disable @typescript-eslint/no-explicit-any */
export const deepReplace = (obj: any, replacements: Record<string, (value: any) => any>): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => deepReplace(item, replacements)) as any;
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        const replacer = replacements[key];

        return [key, replacer ? replacer(value) : deepReplace(value, replacements)];
      }),
    );
  }

  return obj;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export const validatorTotalBalance = (validator: CastCacheValidatorFragment): TokenAmount =>
  validator.lockedBalance.add(validator.unlockedBalance);
