import { type ChainflipAsset } from '@chainflip/utils/chainflip';
import { useGqlQuery } from './useGqlQuery';
import { getAllBoostPoolsCacheQuery } from '../graphql/cache/boost';

export const useAllBoostPools = () => {
  const data = useGqlQuery(getAllBoostPoolsCacheQuery, {
    context: { clientName: 'statechainCache' },
  });

  return {
    ...data,
    boostPools: data.data?.pools?.nodes,
    boostPoolAvailableAmounts:
      data.data?.pools?.nodes.reduce(
        (acc, pool) =>
          Object.assign(acc, {
            [pool.asset]:
              BigInt(pool.availableAmount) + BigInt(acc[pool.asset as ChainflipAsset] ?? 0n),
          }),
        {} as Record<ChainflipAsset, bigint | undefined>,
      ) || ({} as Record<ChainflipAsset, bigint | undefined>),
  };
};
