import { gql } from '../generated/cache';

export const getAllBoostPoolsCacheQuery = gql(/* GraphQL */ `
  query getAllBoostPoolsCache {
    pools: allBoostPools {
      nodes {
        id
        feeTierPips
        asset
        availableAmount
        availableAmountValueUsd
        unavailableAmount
        unavailableAmountValueUsd
        boosters: boostBalancesByPoolId {
          totalCount
        }
      }
    }
  }
`);

export const getBoostPoolCacheQuery = gql(/* GraphQL */ `
  query GetBoostPoolCache($chainflipAsset: ChainflipAsset!, $feeTierPips: Int!) {
    pools: allBoostPools(
      filter: { feeTierPips: { equalTo: $feeTierPips }, asset: { equalTo: $chainflipAsset } }
    ) {
      nodes {
        id
        availableAmount
        unavailableAmount
        availableAmountValueUsd
        unavailableAmountValueUsd
        balances: boostBalancesByPoolId {
          nodes {
            lpIdSs58
            poolShare
            availableAmount
            unavailableAmount
            availableAmountValueUsd
            unavailableAmountValueUsd
          }
        }
      }
    }
  }
`);

export const getAllBoostPoolBalancesForAccount = gql(/* GraphQL */ `
  query AllBoostBalancesForAccount($accountId: String!) {
    balances: allBoostBalances(condition: { lpIdSs58: $accountId }) {
      nodes {
        pool: boostPoolByPoolId {
          id
          asset
          feeTierPips
          availableAmount
          unavailableAmount
        }
        availableAmountValueUsd
        unavailableAmountValueUsd
        unavailableAmount
        availableAmount
        poolShare
      }
    }
  }
`);
